header .b2b_lang_form .b2b_lang_form-en,header .b2b_lang_form .b2b_lang_form-fr,header .b2b_lang_form .b2b_lang_form-ar{
    color: transparent; 
    background-size: cover; 
    background-position: center; 
    border: 0; 
    height: 35px;
    width: 35px;
    border-radius: 50px;
    margin-top: 10px;
    cursor: pointer;
}
header .b2b_lang_form .b2b_lang_form-en{
    background-image: url(./english.png); 
}
header .b2b_lang_form .b2b_lang_form-fr{
    background-image: url(./french.png); 
}
header .b2b_lang_form .b2b_lang_form-ar{
    background-image: url(./arabe.png); 
}
header .logo-header.mostion .logo-imagee{
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
    background-position: center;
    display: block; 
    height: 100%;
}
header .dropdown.dropleft.connect-mobile{
    width: 27px;
    height: 15px;
    float: right;
    margin: 20px 0px;
    padding: 5px;
}




.modal-login-hadeer .b2b-login-error-wrapper {
    width: 100%;
    color: red;
    text-align: center;
}
.modal-login-hadeer .impu-form-line{
    position: relative;
    height: 40px;
    width: 100%;
    border: solid 1px #eee;
    border-radius: 5px;
    margin-bottom: 10px;
}
.modal-login-hadeer .impu-form-line input{
    position: absolute;
    height: 36px !important;
    width: 100%;
    left: 0px;
    padding-left: 60px;
}
.modal-login-hadeer .impu-form-line .fa{
    position: absolute;
    left: 0;
    z-index: 1;
    width: 40px;
    height: 39px;
    display: flex; 
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: #eee;
}
.modal-login-hadeer .pass_eye {
    right: 0px;
    width: 40px;
    height: 40px;
    position: absolute;
    margin-top: 0px;
}
.modal-login-hadeer .content-ihc {
    padding: 15px;
    margin-bottom: 10px;
}

.modal-login-hadeer .impu-form-links-pass{
    text-align: right;
    margin-top: -18px;
}
.modal-login-hadeer .b2b-clear {
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    width: 100%;
}
.modal-login-hadeer>div>div{
    width: 45% !important;
    padding: 30px !important;
    background-color: transparent !important;
}
@media screen and (max-width: 900px) {
    .modal-login-hadeer>div>div{
        width: 60% !important;
    }
    .header-nav .sub-menu{
        margin-top: 0px!important;
    } 
}
@media screen and (max-width: 600px) {
    .modal-login-hadeer>div>div{
        width: 100% !important;
    }  
}
.block-logo-dicktop{
    display: block;
}  
.block-logo-mobile{
    display: none;
} 
@media screen and (max-width: 780px) {
    .block-logo-dicktop{
        display: none;
    }  
    .block-logo-mobile{
        display: block;
    } 
    .content-ihc,.content-ihc>.section-detail-wrapper {
        padding: 0px !important;
    }
    .connxtion_header {
        margin-top: 5px;
    }
    .content-ihc .btn-connecter{
        margin-left: 0px !important;
    }
    
}
.modal-login-hadeer .connxtion_header{
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modal-login-hadeer .connxtion_header h2{
    margin-bottom: 0px;
}
.modal-login-hadeer .connxtion_body{
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
}

.modal-login-hadeer #iump_login_username,.modal-login-hadeer #iump_login_password{
    border: none;
}
.modal-login-hadeer .iump_login_fa_icon{
    font-size: 25px;
    z-index: 99999;
    position: absolute;
    top: 5px;
    left: 5px;
}
.modal-login-hadeer .div_text_ou {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px !important;
}
.modal-login-hadeer .div_text_ou span {
    border: solid 1px #eee;
    width: calc(40% - 25px);
    margin-top: 0px;
}
.modal-login-hadeer .div_text_ou .text-ou {
    border: none;
    width: 50px;
    display: block;
    text-align: center;
}
.modal-login-hadeer .TESTINSCRIVEZVOUS{
    text-align: center;
}
.modal-login-hadeer .TESTINSCRIVEZVOUS a{
    color: red;
}
.header-nav .nav > li > a.active{
    background-color: #ffffff;
    color: #000000;
}
.b2b_lang_form ul{
    height: 55px;
}
.b2b_lang_form ul li{
    display: inline-block;
}
.progress-div {
    background-color: rgb(233, 233, 233);
    border-radius: 0.5rem;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999999999999;
}

.progress {
    background-color: #e11a18;
    height: 5px;
    border-radius: 1rem;
}
#rech-mob_ajax{
    position: relative;
}
.resalt-search,.asp_nores {
    width: 100%;
    min-height: 80px;
    max-height: 400px;
    position: absolute;
    background-color: #fff;
    top: 40px;
    left: 0px;
    z-index: 9999999;
}
.asp_nores {
    min-height: 50px;
    justify-content: center;
    align-items: center;
}
.resalt-search .voir-all-resalt,.resalt-search .voir-all-resalt:hover{
    font-weight: normal;
    font-family: "Open Sans";
    color: rgba(5, 94, 148, 1);
    font-size: 12px;
    line-height: 15px;
    text-shadow: 0px 0px 0px rgb(255 255 255 / 0%);
    padding: 10px 5px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 1);
    display: block;
    text-align: center;
}
.all-resalt-search {
    width: 100%;
    min-height: 80px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}
.asp_group_header {
    background: #DDDDDD;
    background: rgb(246, 246, 246);
    border-radius: 3px 3px 0 0;
    border-top: 1px solid rgb(248, 248, 248);
    border-left: 1px solid rgb(248, 248, 248);
    border-right: 1px solid rgb(248, 248, 248);
    margin: 10px 0 -3px;
    padding: 7px 0 7px 10px;
    position: relative;
    z-index: 1000;
    font-weight: bold;
    font-family: "Open Sans";
    color: rgba(5, 94, 148, 1);
    font-size: 11px;
    line-height: 13px;
    text-shadow: 0px 0px 0px rgb(255 255 255 / 0%);
}

.results-item {
    height: auto;
    background: rgb(255, 255, 255);
    overflow: hidden;
    width: auto;
    margin: 0;
    padding: 3px;
    position: relative;
    border-left: 1px solid rgba(255,255,255,.6);
    border-right: 1px solid rgba(255,255,255,.4);
    animation-delay: 0;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-timing-function: ease;
    backface-visibility: hidden;
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    -webkit-animation-timing-function: ease;
    -webkit-backface-visibility: hidden;
}
.results-item:hover{
    background: #f4f4f4;
}
.results-item .asp_content {
    overflow: hidden;
    width: auto;
    height: auto;
    background: transparent;
    margin: 0;
    padding: 0 10px;
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-family: "Open Sans";
    color: rgba(74, 74, 74, 1);
    font-size: 13px;
    line-height: 13px;
    text-shadow: 0px 0px 0px rgb(255 255 255 / 0%);
}
.results .item .asp_image {
    width: 70px;
    height: 70px;
    margin: 2px 8px 0px 0;
}
.results-item .asp_content .asp_image{
    width: 70px;
    height: 70px;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    background: transparent;
    margin: 4px auto 0 0;
    padding: 0;
    float: left;
    background-position: center;
    background-size: cover;
}
.results-item .asp_content .asp_res_url {
    margin: 0;
    padding: 0;
    line-height: inherit;
    font-weight: bold;
    font-family: "Open Sans";
    color: rgba(20, 84, 169, 1);
    font-size: 14px;
    line-height: 20px;
    text-shadow: 0px 0px 0px rgb(255 255 255 / 0%);
}
.results-item .asp_content span.overlap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}
.asp_spacer{
    background: none repeat scroll 0 0 rgba(204, 204, 204, 1);
    display: block;
    height: 1px;
    margin: 0 !important;
    padding: 0 !important;
    width: 10000px;
}
#search-places.de-search-form {
    border: 1px solid rgb(184, 199, 200);
}
span.searchKey {
    background-color: #aaa;
}
.impu-form-line,.b2b-clear #result {
    height: 42px;
    border: solid 1px #ccc !important;
    overflow: hidden;
}
#iump_login_username,#iump_login_password,.b2b-clear #result{
    height: 40px !important;
    border: none !important;
    background-color: #fff !important;
}
.b2b-clear #result{
    border: solid 1px #ccc !important;
}
.modal-login-hadeer .impu-form-line .fa{
    top: 0px;
}
.icon-login-user{
    margin-top: 0px;
}
.home_search_b2b .btn-connecter{
    margin-bottom: 0px !important;
}
.TESTINSCRIVEZVOUS {
    text-align: center;
}
.impu-form-label-remember::before{
    border-radius: 0px !important;
}

.home-search-input #rech-mob_ajax .probox{
    border: none !important;
}
a.blockedbtnb2b ,a.blockedbtnb2b:hover,a.blockedbtnb2b:focus,a.blockedbtnb2b:active{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bcbcbc;
    border: solid 0px #bcbcbc;
    border-radius: 50px;
    margin-bottom: 0px;
    color: #fff;
    cursor: no-drop;
}
a.blockedbtnb2b.profil{
    color: #aaa !important;
    background-color: transparent;
}
::-webkit-input-placeholder {
    color: #bcbcbc !important;
    opacity: 1
}

::-moz-placeholder {
    color: #bcbcbc !important;
    opacity: 1
}

:-ms-input-placeholder {
    color: #bcbcbc !important;
    opacity: 1
}

::-ms-input-placeholder {
    color: #bcbcbc !important;
    opacity: 1
}

::placeholder {
    color: #888888 !important;
    opacity: 1
}
.continer-notifications {
    width: 300px;
    position: absolute;
    top: 30px;
    right: 0px;
    background: #fff;
    z-index: 9999999999;
    box-shadow: 3px 3px 5px #999;
    padding: 10px;
    border-radius: 5px;
}
.item-notification {
    width: 100%;
    background-color: #cdcdcd44;
    padding: 10px;
    margin-top: 2px;
    color: #000;
}
.item-notification .objet p{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px !important;
    line-height: 15px;
}
.item-notification .msg p{
    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
    margin: 0px !important;
    line-height: 15px;
    font-size: 11px;
}
.item-notification .date p{
    margin: 0px !important;
    font-size: 12px;
    text-align: right;
    line-height: 15px;
}

.liste-notifications {
    max-height: 200px;
    min-height: 20px;
    overflow-x: auto;
}
.all-notifications {
    width: 100%;
    border: solid 1px #AAA;
    height: 30px;
    margin: 0px;
    color: #444;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50px;
}
.pup-up-gerer-compte{
    top: 35px;
}
.header-notifications {
    height: 30px;
    font-size: 20px;
    color: #000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
}

.masque-resalt-search{
    position: fixed;
    top: 50px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 99;
}
.btn-myb2b a{
    border: solid 1px #fff;
    border-radius: 50px !important;
}
.masque-close {
    clear: both;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.3) !important;
    width: 100vh;
    height: 1000vh;
    position: fixed;
    top: 70px;
    left: 35px;
}
#block-fillter-mobile.p0 .masque-close-fillter span,#block-fillter-mobile.p0 .masque-close-fillter {
    display: none;
}
@media only screen and (max-width: 700px) {
    #block-fillter-mobile.p0 .masque-close-fillter {

        display: block;
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 70px;
        backdrop-filter: blur(5px);
        background: rgba(0, 0, 0, 0.3);
        left: 270px;
    }
    #block-fillter-mobile.p0 .masque-close-fillter span{
        width: 30px;
        height: 30px;
        background-color: #fff;
        position: fixed;
        left: 3px;
        top: 3px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
