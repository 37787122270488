button.btn.dropdown-toggle.btn-default {
    display: none;
    }
    .dashboard-main-wrapper{
    padding-top:0!important; 
    }
    .page-wraper {
    background: #fff!important;
    }
    .dashboard-header{
    display: none!important;
    }
    .nav-left-sidebar{
    top: 50!important;
    background: #fff!important;
    }
    .nav-link {
    color: grey!important;
    }
    .footer{
    display: none!important;
    }
    @media only screen and (max-width: 900px) {
    .navbar {
    padding: 0px!important;
    }
    .d-xl-none{
    display: none!important;
    }
    }
    @media only screen and (min-width: 900px) {
    .nav-left-sidebar{
    padding-top: 100px!important;
    position: absolute!important;
    height: auto!important;
    }
    }
    p{
    margin-bottom: 0px !important;
    margin-top:10px !important;
    }
    span.icon-case {
    margin-right: 5px;
    }

    .nav-item-hide,.nav-left-sidebar.sidebar{
        display: none !important;
        }
        .dashboard-wrapper{
        margin-left: 0px !important;
        }
        textarea.form-control{
        height: 100px!important;
        }
        .leftcontact
        {
        width: 50%;
        display: inline-block;
        }
        .rightcontact
        {
        width: 50%;
        display: inline-block;
        float: right;
        }
        .star{
        color: #e11e18;
        }
        input[type=radio] {
        margin-left: 20px !important;
        margin-right: 5px !important;
        }
        .text-etap,.text-number{
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        }
        .text-number{
        display: flex;
        justify-content: center;
        align-items: center;
        }
        .text-number span{
        background-color: #eee;
        color: #3c3c3b;
        width: 70px;
        border-radius: 64px;
        height: 70px;
        display: flex;
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 10px;
        justify-content: center;
        align-items: center;
        position: relative;
        }
        .arc-image{
           position: absolute;
           width: 70px;
           height: 70px;
           background-size: 70px 70px;
           background-position: -5px 0px;
        }
        .active .text-number span{
        background-color: #e11a18;
        color: #fff;
        }
        .text-etap{
        color: #3c3c3b;
        }
        .active .text-etap{
        color: #e11a18;
        }
        .text-number-1 ::after{
        content: "";
        border-top: solid 1px #eee;
        width: calc( 50% - 50px );
        position: absolute;
        right: 0px;
        }
        .text-number-2 ::after{
        content: "";
        border-top: solid 1px #eee;
        width: calc( 50% - 50px );
        position: absolute;
        right: 0px;
        }
        .date_naissance{
           display: flex;
           justify-content: inherit;
           align-items: center;
        }
        .date_naissance .bootstrap-select{
           width: 50px !important;
        }
     
        .text-number-2 ::before{
        content: "";
        border-top: solid 1px #eee;
        width: calc( 50% - 50px );
        position: absolute;
        left: 0px;
        }
        .text-number-3 ::after{
        content: "";
        border-top: solid 1px #eee;
        width: calc( 50% - 50px );
        position: absolute;
        left: 0px;
        }
        .card{
        box-shadow: unset;
        border: unset;
        }
        .btn-b2b.suivant{
           float: right;
           margin-top: 20px;
        }
        .suivant-text{
         float: right;
         margin-top: 25px;
         margin-right: 20px;
         font-size: 20px;
         text-transform: uppercase;
        }
        .m-form-b2b{
             margin-left: 4rem !important;
             margin-right: 4rem !important;
        }
        @media only screen and (max-width: 700px) {
         .m-form-b2b{
             margin-left: 0rem !important;
             margin-right: 0rem !important;
         }
        }
        @media only screen and (max-width: 900px) {
         .m-form-b2b{
             margin-left: 1.5rem !important;
             margin-right: 1.5rem !important;
         }
        }
        #responsecontainer .btn.btn_clicked,
        .disponibilite .btn.btn_clicked,
        .type_contrat .btn.btn_clicked{
             border: none;
             background-color: #aaa;
             border-radius: 10px;
        }
        #responsecontainer .btn.btn_clicked,
        .disponibilite .btn.btn_clicked,
        .type_contrat .btn.btn_clicked{
             border: none;
             background-color: #aaa !important;
             border-radius: 10px;
        }
        #responsecontainer .btn.btn_not_clicked,
        .disponibilite  .btn.btn_not_clicked,
        .type_contrat .btn.btn_not_clicked{
            border: none;
            background-color: #eee !important;
            border-radius: 10px;
         }
         #responsecontainer .btn.btn_clicked, 
         #responsecontainer .btn.btn_not_clicked,
         .disponibilite .btn.btn_clicked, 
         .disponibilite .btn.btn_not_clicked,
         .type_contrat .btn.btn_clicked, 
         .type_contrat .btn.btn_not_clicked
         {
             position: relative;
             margin: 15px;
             min-width: 150px;
             max-width: 100%;
             padding: 10px;
        }
         #responsecontainer .btn.btn_clicked::after, 
         #responsecontainer .btn.btn_not_clicked::after,
         .disponibilite .btn.btn_clicked::after, 
         .disponibilite .btn.btn_not_clicked::after,
         .type_contrat .btn.btn_clicked::after, 
         .type_contrat .btn.btn_not_clicked::after
         {
             content: "?";
             background-color: #eee;
             border-radius: 10px;
             position: absolute;
             width: 21px;
             height: 21px;
             top: -10px;
             right: -10px;
             display: flex;
             z-index: 5;
             justify-content: center;
             align-items: center;
             border: solid 1px #fff;
        }
        #responsecontainer .btn.btn_clicked::after,
        .disponibilite .btn.btn_clicked::after,
        .type_contrat .btn.btn_clicked::after
        {
             content: "✓";
             background-color: #e11a18;
             color: #fff;
        }
        .form-group {
             margin-bottom: 0px !important;
        }
     
        .bootstrap-select {
             display: none;
             height: 0px;
         }
         select option[value=""] {
             color: #eee !important;
         }
         select {
             color: #aaa !important;
         }
         .disponibilite {
             text-align: center;
             display: block;
             justify-content: center;
             align-items: center;
         }
         #input_disponibilite_preavis,#input_disponibilite_fin{
             width: 100%;
             text-align: center;
             height: 40px;
             border-radius: 10px;
             display: block;
             margin-top: 10px;
             border: solid 1px #aaa;
         }
         input[type=radio   ]:not(old){
             background-color: red !important;
             color: red !important;
         }
         .m-form-b2b{
             margin-left: 4rem !important;
             margin-right: 4rem !important;
        }
        @media only screen and (max-width: 700px) {
         .m-form-b2b{
             margin-left: 0rem !important;
             margin-right: 0rem !important;
         }
        }
        
        #input_disponibilite,#input_type_emploi{
            height: 0px !important;
            opacity: 0 !important;
            width: 100%; 
        }
       
        input.input_autre_competence {
             background: transparent;
             border: none;
             text-align: center;
             text-transform: capitalize;
         }
.block-competence_ {
   display: flex;
   margin-left: 20px;
}
.block-competence_ .input-competence{
   width: calc(70% - 20px);
}
.block-competence_ .input-competence-p{
   width: 30%;
}
.block-competence_ span{
   width: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 40px;
   font-size: 23px;
}
.btn-remove-formation::after, .btn-add-formation::after {
   content: "-";
   position: absolute;
   left: 0px;
   top: 8px;
   background-color: #e11a18;
   width: 20px;
   height: 20px;
   color: #fff;
   border-radius: 50px;
   font-size: 30px;
   line-height: 15px;
}
.btn-add-formation::after {
   content: "+";
   font-size: 20px;
   line-height: 18px;
}
.btn-remove-formation, .btn-add-formation {
   position: relative;
   padding-left: 25px;
   margin-bottom: 10px;
}
.test-titel{
   border: solid 1px #e11a18;
   padding: 10px;
   width: fit-content;
   padding-left: 50px;
   border-radius: 50px;
}
.test-experience span,.test-formation span{
   width: 40px;
   height: 40px;
   display: block;
   margin-top: -10px;
   margin-left: -50px;
   float: left;
   background-size: 40px 40px;
}
.block-interes_freelance,.block-interes_emploi{
   display: none;
}

.message-sind-fin {
   display: grid;
   justify-content: center;
   align-items: center;
   text-align: center;
}
.message-sind-fin label{
   font-size: 30px;
}
p.message-sind-text-1 {
   font-size: 20px;
   font-weight: bold;
   margin: 0px !important;
}
p.message-sind-text-2 {
   margin: 0px !important;
   font-weight: 600;
}
p.message-sind-text-2 a{
   color: red;
}
::-webkit-input-placeholder { 
   color: #bbbdc0;
}
::-moz-placeholder { 
   color: #bbbdc0;
}
:-ms-input-placeholder {
   color: #bbbdc0;
}
:-moz-placeholder { 
   color: #bbbdc0;
}
.block-expert-inscription input,.block-expert-inscription select,.block-expert-inscription textarea{
   border-color: #bbbdc0 !important;
   border-radius: 0px !important;
   color: #646464 !important;
}
.block-expert-inscription #responsecontainer .btn_not_clicked,#responsecontainer .btn_not_clicked::after{
   background-color: #bbbdc0 !important;
}
.block-expert-inscription .btn.btn-b2b{
   padding: 5px 10px ;
   border-radius: 50px !important;
   background-color: transparent ;
   border: solid 1px red !important;
}

.block-expert-inscription label{
   margin-bottom: 0px;
   margin-top: 10px;
}
.block-expert-inscription .flex-center{
   display: flex;
   justify-content: flex-start;
   align-items: center;
}
.block-expert-inscription .form-control{
   margin-bottom: 0px;
}

.block-expert-inscription.inscription2 select{
   margin-top: 5px;
}
.block-expert-inscription.inscription3 #formations_div_inner label{
   margin-top: 0px;
}
.block-expert-inscription.inscription3 #experiences_div label{
   margin-top: 0px;
}
@media only screen and (max-width: 700px) {
   .m-form-b2b{
      margin-left: 0rem ;
      margin-right: 0rem ;
      width: 100%;
   }
   .informations-personnelles-professionnelles .col-md-4{
      display: none;
   }
   .informations-personnelles-professionnelles .col-md-4.active{
      display: block;
   }
   .block-expert-inscription .block-btn-precedent-etape-suivant{
      flex-wrap: wrap-reverse;
   }
   .block-expert-inscription .block-btn-precedent-etape-suivant .btn.btn-b2b.suivant ,.block-expert-inscription .block-btn-precedent-etape-suivant .suivant-text{
      margin: 0px;
   }
   .block-expert-inscription .block-btn-precedent-etape-suivant {
      margin-top: 10px;
   }
   .block-expert-inscription.inscription3 .test-titel.test-formation,.block-expert-inscription.inscription3 .test-titel.test-experience{
      font-size: 13px;
   }
   .block-expert-inscription.inscription3 #formations_div .leftcontact,.block-expert-inscription.inscription3 #formations_div .rightcontact {
      width: 100% !important;
      padding-left: 0px;
      padding-right: 15px;
   }
   .block-expert-inscription.inscription3 #experiences_div_inner .leftcontact,.block-expert-inscription.inscription3 #experiences_div_inner .rightcontact {
      width: 100% !important;
      padding-left: 10px !important;
      padding-right: 15px;
   }
   
   
}
.text-transform-unset{
   text-transform: unset !important;
}